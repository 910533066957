import {
  ReservationArrayResponse,
  ReservationResponse,
} from 'src/app/interfaces/response';

import { AppConfigService } from 'src/app/app-config.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Observable } from 'rxjs';
import { Reservation } from 'src/app/interfaces/reservation';

@Injectable({
  providedIn: 'root',
})
export class ReservationsService {
  private API_GATEWAY = '';

  constructor(
    private _http: HttpClient,
    private _appConfig: AppConfigService,
    private _keycloak: KeycloakService,
  ) {
    this.API_GATEWAY = this._appConfig.getConfig().API_GATEWAY;
  }

  getReservationsPage(
    pageSize: number,
    pageNumber: number,
    filters: { [prop: string]: string } = {},
  ): Observable<HttpResponse<ReservationArrayResponse>> {
    let url = `${this.API_GATEWAY}/reservations?limit=${pageSize}&offset=${
      pageSize * (pageNumber - 1)
    }`;

    for (const property in filters) {
      const value = filters[property];
      url += `&${property}=${value}`;
    }

    return this._http.get<ReservationArrayResponse>(url, {
      observe: 'response',
    });
  }

  refundReservation(reservation: Reservation): Observable<ReservationResponse> {
    const url = `${this.API_GATEWAY}/reservations/${reservation.country_code}/${reservation.party_id}/${reservation.id}/refund`;
    return this._http.post<ReservationResponse>(url, null);
  }

  cancelReservation(reservation: Reservation): Observable<ReservationResponse> {
    const url = `${this.API_GATEWAY}/reservations/${reservation.country_code}/${reservation.party_id}/${reservation.id}/cancel`;
    const body = {
      reason: 'OPERATOR',
    };
    return this._http.post<ReservationResponse>(url, body);
  }
}
