export interface TariffAltText {
  language: string;
  text: string;
}

export enum PriceComponentTypeEnum {
  EARLY_ARRIVAL_TIME = 'EARLY_ARRIVAL_TIME',
  LATE_ARRIVAL_TIME = 'LATE_ARRIVAL_TIME',
  EARLY_ARRIVAL_FLAT = 'EARLY_ARRIVAL_FLAT',
  LATE_ARRIVAL_FLAT = 'LATE_ARRIVAL_FLAT',
  RESERVED_STAY_TIME = 'RESERVED_STAY_TIME',
  RESERVED_STAY_FLAT = 'RESERVED_STAY_FLAT',
  EARLY_DEPARTURE_TIME = 'EARLY_DEPARTURE_TIME',
  LATE_DEPARTURE_TIME = 'LATE_DEPARTURE_TIME',
  EARLY_DEPARTURE_FLAT = 'EARLY_DEPARTURE_FLAT',
  LATE_DEPARTURE_FLAT = 'LATE_DEPARTURE_FLAT',
}

export interface PriceComponent {
  type: PriceComponentTypeEnum | null;
  price: number;
  vat: number;
  step_size: number;
}

export enum DayOfWeekEnum {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 7,
}

export interface Restriction {
  start_time: string | null;
  end_time: string | null;
  start_date: string | null;
  end_date: string | null;
  min_duration_minutes: number;
  max_duration_minutes: number;
  day_of_week: DayOfWeekEnum[] | null;
}

export interface TariffElement {
  price_components: PriceComponent[] | null;
  restrictions: Restriction | null;
}

export interface Tariff {
  country_code: string;
  party_id: string;
  id: string;
  currency: string;
  tariff_alt_text: TariffAltText[] | null;
  elements: TariffElement[] | null;
  last_updated: Date | null;
}
