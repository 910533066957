import { OCPIToken } from './OCPIToken';

export interface Reservation {
  country_code: string;
  party_id: string;
  token: OCPIToken;
  start_time: Date;
  end_time: Date;
  id: string | null;
  user_id: string;
  location_country_code: string;
  location_party_id: string;
  location_id: string;
  evse_uid: string;
  authorization_reference: string;
  status: ReservationStatus;
  cancelled_at: Date | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cancellation_reason: any | null; // TODO add explicit type
  tariff_id: string;
  currency: string;
  reservation_fee: Price | null;
  reserved_stay_fee: Price | null;
  early_arrival_fee: Price | null;
  late_arrival_fee: Price | null;
  early_departure_fee: Price | null;
  late_departure_fee: Price | null;
  refund_amount: Price | null;

  // TODO: Remove any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reservation_periods: ReservationPeriods | any;

  reservation_fee_confirmed_at: Date | null;
  reserved_stay_confirmed_at: Date | null;
  early_arrival_fee_confirmed_at: Date | null;
  late_arrival_fee_confirmed_at: Date | null;
  early_departure_fee_confirmed_at: Date | null;
  late_departure_fee_confirmed_at: Date | null;
  refund_confirmed_at: Date | null;
  created_at: Date;
  last_updated: Date;
}

export interface Price {
  incl_vat: number;
  excl_vat: number;
}

export interface ReservationPeriods {
  start_date_time: Date;
  dimensions: [
    {
      type: ReservationPeriodDimensionTypeEnum;
      volume: number;
    },
  ];
}

export enum ReservationStatus {
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  CANCELLED = 'CANCELLED',
}

export enum ReservationPeriodDimensionTypeEnum {
  EARLY_ARRIVAL_TIME = 'EARLY_ARRIVAL_TIME',
  LATE_ARRIVAL_TIME = 'LATE_ARRIVAL_TIME',
  RESERVED_STAY_TIME = 'RESERVED_STAY_TIME',
  EARLY_DEPARTURE_TIME = 'EARLY_DEPARTURE_TIME',
  LATE_DEPARTURE_TIME = 'LATE_DEPARTURE_TIME',
}
