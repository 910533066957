import { Component, OnInit } from '@angular/core';
import { Tariff, TariffElement } from 'src/app/interfaces/tariff';

import { TariffService } from '../services/tariff.service';

@Component({
  selector: 'app-tariff-list',
  templateUrl: './tariff-list.component.html',
  styleUrls: ['./tariff-list.component.css'],
})
export class TariffListComponent implements OnInit {
  tariffs!: Tariff[];

  constructor(private tariffService: TariffService) {}

  ngOnInit(): void {
    this.tariffService.getTariffs().subscribe((resp) => {
      this.tariffs = resp.data;

      this.tariffs.forEach((tariff: Tariff) => {
        tariff.tariff_alt_text = tariff.tariff_alt_text
          ? tariff.tariff_alt_text.filter(
              (alt_text) => alt_text.language == 'en',
            )
          : [];
      });
    });
  }

  public getDaysTariffApplies(tariff: Tariff) {
    // Get list of element restrictions in tariff
    let validDays: string[] = [];
    // Tariff elements may be null
    if (tariff.elements) {
      for (const element of tariff.elements) {
        validDays.push(this.GetValidDaysForElement(element));
      }
      // Trim duplicates
      validDays = [...new Set(validDays)];
    }

    return validDays.join(', ');
  }

  GetValidDaysForElement(element: TariffElement) {
    if (element.restrictions) {
      const dayOfWeekJsonString = element.restrictions.day_of_week
        ? element.restrictions.day_of_week.join(', ')
        : 'None';
      return dayOfWeekJsonString;
    } else {
      return 'Any';
    }
  }
}
