import { Component } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile, KeycloakTokenParsed } from 'keycloak-js';
import { defer, from } from 'rxjs';
import { ChargingSpacesService } from 'src/app/charging-spaces/services/charging-spaces.service';
import { Reservation } from 'src/app/interfaces/reservation';
import { ReservationsService } from 'src/app/reservations/services/reservations.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent {
  user: KeycloakProfile = {};
  token: KeycloakTokenParsed | undefined;
  country_code = '';
  party_id = '';
  reservations: Reservation[] = [];
  reservations_count = 0;
  spaces_count = 0;

  constructor(
    private _keycloakService: KeycloakService,
    private _reservationsService: ReservationsService,
    private _chargingSpacesService: ChargingSpacesService,
  ) {
    defer(() => from(this._keycloakService.loadUserProfile())).subscribe(
      (user) => {
        this.user = user;
        this.token = this._keycloakService.getKeycloakInstance().tokenParsed;
        if (this.token) {
          this.country_code = this.token['country_code'];
          this.party_id = this.token['party_id'];
        }
      },
    );
    this._reservationsService
      .getReservationsPage(50, 1, {})
      .subscribe((resp) => {
        const total_header = resp.headers.get('X-Total-Count');
        this.reservations_count = total_header ? parseInt(total_header) : 0;
        if (resp.body?.data) {
          this.reservations = resp.body.data.filter((reservation) => {
            return (
              new Date(reservation.start_time).toDateString() ==
              new Date().toDateString()
            );
          });
        }
      });
    this._chargingSpacesService.getSpaces().subscribe((resp) => {
      this.spaces_count = resp.data.length;
    });
  }
}
