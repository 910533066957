<clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading">
  <clr-dg-column [clrDgField]="'id'" [clrDgColType]="'string'">
    ID
  </clr-dg-column>
  <clr-dg-column [clrDgField]="'user_id'" [clrDgColType]="'string'">
    USER ID
  </clr-dg-column>
  <clr-dg-column [clrDgField]="'evse_uid'" [clrDgColType]="'string'">
    EVSE UID
  </clr-dg-column>
  <clr-dg-column [clrDgField]="'status'"> Status </clr-dg-column>
  <clr-dg-column> Start Time </clr-dg-column>
  <clr-dg-column> End Time </clr-dg-column>
  <clr-dg-column [clrDgField]="'last_updated'"> Last Updated </clr-dg-column>

  <clr-dg-row
    *ngFor="let reservation of reservations"
    [clrDgItem]="reservation"
  >
    <clr-dg-cell>{{ reservation.id }}</clr-dg-cell>
    <clr-dg-cell>{{ reservation.user_id }}</clr-dg-cell>
    <clr-dg-cell>{{ reservation.evse_uid }}</clr-dg-cell>
    <clr-dg-cell>
      <span class="label {{ getReservationStatusLabel(reservation.status) }}">{{
        reservation.status
      }}</span>
    </clr-dg-cell>
    <clr-dg-cell>{{
      reservation.start_time | date: "d/M/yy, H:mm"
    }}</clr-dg-cell>
    <clr-dg-cell>{{ reservation.end_time | date: "d/M/yy, H:mm" }}</clr-dg-cell>
    <clr-dg-cell>{{
      reservation.last_updated | date: "d/M/yy, H:mm"
    }}</clr-dg-cell>

    <clr-dg-row-detail *clrIfExpanded class="clr-row">
      <div class="clr-col-2">
        <div class="card">
          <div class="card-header">Quick look information</div>
          <div class="card-block">
            <ul class="list-unstyled">
              <li>Location: {{ reservation.location_id }}</li>
              <li>
                Location owner: {{ reservation.location_country_code }}-{{
                  reservation.location_party_id
                }}
              </li>
              <br />
              <li>
                Duration:
                {{
                  getReservationDuration(
                    reservation.start_time,
                    reservation.end_time
                  )
                }}
                minutes
              </li>
              <li>
                Buffer start time:
                {{
                  getReservationBufferTime(reservation) | date: "d/M/yy, H:mm"
                }}
              </li>
            </ul>
          </div>
          <div class="card-block" *ngIf="reservation.refund_amount">
            <span
              >Refunded (incl. vat): £{{
                reservation.refund_amount.incl_vat
              }}</span
            >
          </div>
          <div class="card-block" *ngIf="reservation.cancelled_at">
            <span
              >Cancelled:
              {{ reservation.cancelled_at | date: "d/M/yy, H:mm" }}</span
            >
            <br />
            <span>Reason was {{ reservation.cancellation_reason }}</span>
          </div>
        </div>
      </div>
      <div class="clr-col-4">
        <div class="card">
          <div class="card-header">
            Tariff: {{ getReservationTariff(reservation).id | titlecase }}
          </div>
          <div
            class="card-block"
            *ngFor="
              let element of getReservationTariff(reservation).elements;
              index as i
            "
          >
            <span class="card-title">Element {{ i + 1 }}</span>
            <table class="clr-col-12 table">
              <thead>
                <tr>
                  <th class="clr-col-2">Name</th>
                  <th class="clr-col-2">Type</th>
                  <th class="clr-col-2">Price (£)</th>
                  <th class="clr-col-2">VAT (%)</th>
                  <th class="clr-col-2">Step Size (seconds)</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let priceComponent of element.price_components;
                    index as j
                  "
                >
                  <td>Pricing {{ j + 1 }}</td>
                  <td>{{ priceComponent.type }}</td>
                  <td>{{ priceComponent.price }}</td>
                  <td>{{ priceComponent.vat }}</td>
                  <td>{{ priceComponent.step_size }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="clr-col-3">
        <div class="card">
          <div class="card-header">Reservation periods</div>
          <div class="card-block">
            <p>This shows how long the reservation was in each state</p>
            <table class="table">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Volume (minutes)</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let period of getReservationPeriods(reservation);
                    index as k
                  "
                >
                  <td>{{ period.dimension }}</td>
                  <td>{{ period.time }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="clr-col-3">
        <div class="card">
          <div class="card-header">Actions</div>
          <div class="card-block">
            Actions you can perform on this reservation
            <!-- <br>
            <br>
            <button
              [disabled]="reservation.status == 'CANCELLED' || reservation.refund_amount"
              class="btn btn-warning"
              (click)="reassignChargingSpaceForReservation()"
              type="reassign"
            >
              Reassign Charging Space
            </button> -->
            <br />
            <br />
            <button
              [disabled]="
                reservation.status === 'PENDING' ||
                reservation.refund_amount
              "
              class="btn btn-warning"
              (click)="refundModalOpen = true"
              type="refund"
              id="refundReservationButton"
            >
              Refund Reservation
            </button>
            <br />
            <br />
            <button
              [disabled]="
                reservation.status === 'CANCELLED' || reservation.refund_amount
              "
              class="btn btn-danger"
              (click)="cancelModalOpen = true"
              type="cancel"
              id="cancelReservationButton"
            >
              Cancel Reservation
            </button>
          </div>
        </div>
      </div>

      <clr-modal [(clrModalOpen)]="refundModalOpen" id="refundModal">
        <h3 class="modal-title" *ngIf="!refundMessage">
          Are you sure you want to refund?
        </h3>
        <div class="modal-body" *ngIf="!refundMessage">
          <p>Reservation id: {{ reservation.id }}</p>

          <table class="clr-col-12 table">
            <thead>
              <tr>
                <th class="clr-col-2">Reservation fee</th>
                <th class="clr-col-2">Reserved stay fee</th>
                <th class="clr-col-2">Early arrival fee</th>
                <th class="clr-col-2">Late arrival fee</th>
                <th class="clr-col-2">Early departure fee</th>
                <th class="clr-col-2">Late departure fee</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{
                    reservation.reservation_fee
                      ? reservation.reservation_fee.incl_vat
                      : 0
                  }}
                </td>
                <td>
                  {{
                    reservation.reserved_stay_fee
                      ? reservation.reserved_stay_fee.incl_vat
                      : 0
                  }}
                </td>
                <td>
                  {{
                    reservation.early_arrival_fee
                      ? reservation.early_arrival_fee.incl_vat
                      : 0
                  }}
                </td>
                <td>
                  {{
                    reservation.late_arrival_fee
                      ? reservation.late_arrival_fee.incl_vat
                      : 0
                  }}
                </td>
                <td>
                  {{
                    reservation.early_departure_fee
                      ? reservation.early_departure_fee.incl_vat
                      : 0
                  }}
                </td>
                <td>
                  {{
                    reservation.late_departure_fee
                      ? reservation.late_departure_fee.incl_vat
                      : 0
                  }}
                </td>
              </tr>
            </tbody>
          </table>

          <p>
            Total to refund (incl. vat):
            {{ getReservationTotalCostInclVat(reservation) | currency: "£" }}
          </p>
        </div>
        <div class="modal-body" *ngIf="refundMessage">
          <div class="card">
            <div class="card-block">
              {{ refundMessage }}
            </div>
          </div>
        </div>
        <div class="modal-footer" *ngIf="!refundMessage">
          <button
            type="button"
            class="btn btn-outline"
            (click)="refundModalOpen = false"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            (click)="refundReservation(reservation)"
          >
            Refund
          </button>
        </div>
        <div class="modal-footer" *ngIf="refundMessage">
          <button
            type="button"
            class="btn btn-outline"
            (click)="refundModalOpen = false; refundMessage = null; ngOnInit()"
          >
            Close
          </button>
        </div>
      </clr-modal>

      <clr-modal [(clrModalOpen)]="cancelModalOpen" id="cancelModal">
        <h3 class="modal-title" *ngIf="!cancelMessage">
          Are you sure you want to cancel?
        </h3>
        <div class="modal-body" *ngIf="!cancelMessage" id="cancelModalPending">
          <p>Reservation id: {{ reservation.id }}</p>
          <p>
            This reservation will be refunded if the 'No show expiry' has not
            been reached
          </p>

          <p>
            Total reservation cost (incl. vat):
            {{ getReservationTotalCostInclVat(reservation) | currency: "£" }}
          </p>
        </div>
        <div class="modal-body" *ngIf="cancelMessage" id="cancelModalComplete">
          <div class="card">
            <div class="card-block">
              {{ cancelMessage }}
            </div>
          </div>
        </div>
        <div class="modal-footer" *ngIf="!cancelMessage">
          <button
            type="button"
            class="btn btn-outline"
            (click)="cancelModalOpen = false"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            (click)="cancelReservation(reservation)"
          >
            Cancel and refund reservation
          </button>
        </div>
        <div class="modal-footer" *ngIf="cancelMessage">
          <button
            type="button"
            class="btn btn-outline"
            (click)="cancelModalOpen = false; cancelMessage = null; ngOnInit()"
          >
            Close
          </button>
        </div>
      </clr-modal>
    </clr-dg-row-detail>
  </clr-dg-row>
  <clr-dg-footer>
    <clr-dg-pagination
      #pagination
      [clrDgPageSize]="10"
      [clrDgTotalItems]="total_reservations"
    >
      <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]"
        >Reservations per page</clr-dg-page-size
      >
      {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of
      {{ pagination.totalItems }}
      {{ pagination.totalItems === 1 ? "reservation" : "reservations" }}
    </clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>
