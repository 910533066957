<!-- Alert bar -->
<div class="alert alert-danger" role="alert" *ngIf="displayError">
  <div class="alert-items">
    <div class="alert-item static">
      <div class="alert-icon-wrapper">
        <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
      </div>
      <span
        class="alert-text"
        *ngIf="
          errorMessage;
          then displayErrorMessage;
          else displayGenericMessage
        "
      >
      </span>
    </div>
  </div>
</div>

<ng-template #displayErrorMessage>
  {{ errorMessage }}
</ng-template>
<ng-template #displayGenericMessage>
  Something went wrong. Please try again later.
</ng-template>

<!-- Form -->
<form clrForm clrLayout="horizontal" [formGroup]="tariffForm">
  <clr-control-container>
    <label class="clr-required-mark" for="id">Tariff ID</label>
    <input id="id-input" clrControl type="text" formControlName="id" />
    <clr-control-error *clrIfError="'required'">
      This field is required
    </clr-control-error>
  </clr-control-container>

  <clr-control-container>
    <label class="clr-required-mark" for="currency">Currency</label>
    <input
      id="currency-input"
      clrControl
      type="text"
      formControlName="currency"
    />
    <clr-control-error *clrIfError="'required'">
      This field is required
    </clr-control-error>
  </clr-control-container>

  <clr-control-container>
    <label for="tariff_alt_text">Alternate text</label>
    <input
      id="alt-text-input"
      clrControl
      type="text"
      formControlName="tariff_alt_text"
    />
  </clr-control-container>

  <br />

  <div class="form-container">
    <form [formGroup]="tariffForm">
      <div class="form-group">
        <clr-accordion [clrAccordionMultiPanel]="true" formArrayName="elements">
          <clr-accordion-panel
            *ngFor="let element of getElements(tariffForm); index as i"
            [clrAccordionPanelOpen]="true"
          >
            <clr-accordion-title
              >Tariff element {{ i + 1 }} | Valid days:
              {{ GetValidDays(element) }}</clr-accordion-title
            >
            <clr-accordion-content
              class="clr-row"
              [formGroupName]="i"
              id="{{ 'element-' + i }}"
            >
              <!-- LHS Static Form -->
              <div class="clr-col-6 form-group">
                <div formGroupName="restrictions">
                  <clr-checkbox-container>
                    <clr-checkbox-wrapper
                      id="{{
                        'element-' + i + '-disable-restrictions-checkbox'
                      }}"
                    >
                      <input
                        type="checkbox"
                        (change)="toggleElementInputs(element)"
                        clrCheckbox
                        name="disable_restrictions"
                        required
                      />
                      <label>Disable restrictions</label>
                    </clr-checkbox-wrapper>
                    <clr-control-helper
                      >This will make the price components always
                      apply</clr-control-helper
                    >
                  </clr-checkbox-container>
                  <clr-control-container>
                    <label for="start_time"
                      >Start time <br /><span class="tooltip">(HH:MM)</span>
                    </label>
                    <input
                      id="{{ 'element-' + i + '-start-time-input' }}"
                      clrControl
                      type="text"
                      formControlName="start_time"
                    />
                  </clr-control-container>
                  <clr-control-container>
                    <label for="end_time"
                      >End time <br /><span class="tooltip">(HH:MM)</span>
                    </label>
                    <input
                      id="{{ 'element-' + i + '-end-time-input' }}"
                      clrControl
                      type="text"
                      formControlName="end_time"
                    />
                  </clr-control-container>
                  <clr-control-container>
                    <clr-date-container>
                      <label class="clr-required-mark" for="start_date"
                        >Start date <br /><span class="tooltip"
                          >(MM/DD/YYYY)</span
                        >
                      </label>
                      <input
                        id="{{ 'element-' + i + '-start-date-input' }}"
                        clrControl
                        type="date"
                        clrDate
                        formControlName="start_date"
                      />
                    </clr-date-container>
                  </clr-control-container>
                  <clr-control-container>
                    <clr-date-container>
                      <label for="end_date"
                        >End date <br /><span class="tooltip"
                          >(MM/DD/YYYY)</span
                        >
                      </label>
                      <input
                        id="{{ 'element-' + i + '-end-date-input' }}"
                        clrControl
                        type="date"
                        clrDate
                        formControlName="end_date"
                      />
                    </clr-date-container>
                  </clr-control-container>
                  <clr-control-container>
                    <label for="min_duration_minutes"
                      >Minimum duration (minutes)</label
                    >
                    <input
                      id="{{ 'element-' + i + '-min-duration-input' }}"
                      clrControl
                      type="number"
                      formControlName="min_duration_minutes"
                    />
                  </clr-control-container>
                  <clr-control-container>
                    <label for="max_duration_minutes"
                      >Maximum duration (minutes)</label
                    >
                    <input
                      id="{{ 'element-' + i + '-max-duration-input' }}"
                      clrControl
                      type="number"
                      formControlName="max_duration_minutes"
                    />
                  </clr-control-container>
                  <clr-combobox-container>
                    <label for="day_of_week">Valid days</label>
                    <clr-combobox
                      id="{{ 'element-' + i + '-day-of-week-input' }}"
                      formControlName="day_of_week"
                      name="multiSelect"
                      clrMulti="true"
                    >
                      <ng-container *clrOptionSelected="let selected">
                        {{ selected }}
                      </ng-container>
                      <clr-options>
                        <clr-option [clrValue]="dayOfWeek[1]">
                          Monday
                        </clr-option>
                        <clr-option [clrValue]="dayOfWeek[2]">
                          Tuesday
                        </clr-option>
                        <clr-option [clrValue]="dayOfWeek[3]">
                          Wednesday
                        </clr-option>
                        <clr-option [clrValue]="dayOfWeek[4]">
                          Thursday
                        </clr-option>
                        <clr-option [clrValue]="dayOfWeek[5]">
                          Friday
                        </clr-option>
                        <clr-option [clrValue]="dayOfWeek[6]">
                          Saturday
                        </clr-option>
                        <clr-option [clrValue]="dayOfWeek[7]">
                          Sunday
                        </clr-option>
                      </clr-options>
                    </clr-combobox>
                    <clr-control-helper
                      >Select all valid days</clr-control-helper
                    >
                  </clr-combobox-container>
                </div>
              </div>
              <!-- RHS Price Components -->
              <div class="clr-col-6 form-group">
                <div class="card">
                  <div class="card-block">
                    <div class="card-title">Price components</div>
                    <div class="card-text">
                      These are the additional charges for the users to use a
                      reservable space.
                    </div>
                  </div>
                </div>
                <br />
                <clr-accordion
                  [clrAccordionMultiPanel]="true"
                  formArrayName="price_components"
                >
                  <clr-accordion-panel
                    *ngFor="
                      let priceComponent of getPriceComponents(element);
                      index as j
                    "
                    [clrAccordionPanelOpen]="true"
                  >
                    <clr-accordion-title
                      >Pricing {{ j + 1 }}
                      <div
                        class="alert-icon-wrapper clr-error"
                        [ngStyle]="{ color: 'red' }"
                        *ngIf="!priceComponent.valid"
                      >
                        Invalid
                      </div>
                    </clr-accordion-title>
                    <clr-accordion-content
                      [formGroupName]="j"
                      class="clr-form-compact clr-row"
                    >
                      <clr-select-container class="clr-col-12">
                        <label class="clr-required-mark" for="type">Type</label>
                        <select
                          id="{{
                            'element-' +
                              i +
                              '-price-component-' +
                              j +
                              '-type-input'
                          }}"
                          clrSelect
                          formControlName="type"
                        >
                          <option value="EARLY_ARRIVAL_TIME">
                            Early Arrival Time (Hours)
                          </option>
                          <option value="EARLY_ARRIVAL_FLAT">
                            Early Arrival Flat
                          </option>
                          <option value="LATE_ARRIVAL_TIME">
                            Late Arrival Time (Hours)
                          </option>
                          <option value="LATE_ARRIVAL_FLAT">
                            Late Arrival Flat
                          </option>
                          <option value="RESERVED_STAY_TIME">
                            Reserved Stay Time (Hours)
                          </option>
                          <option value="RESERVED_STAY_FLAT">
                            Reserved Stay Flat
                          </option>
                          <option value="EARLY_DEPARTURE_TIME">
                            Early Departure Time (Hours)
                          </option>
                          <option value="EARLY_DEPARTURE_FLAT">
                            Early Departure Flat
                          </option>
                          <option value="LATE_DEPARTURE_TIME">
                            Late Departure Time (Hours)
                          </option>
                          <option value="LATE_DEPARTURE_FLAT">
                            Late Departure Flat
                          </option>
                        </select>
                        <clr-control-helper class="clr-subtext"
                          >Type to bill against, *_FLAT is a constant
                          independent fee whereas the others types link to the
                          duration of a specific reservation
                          period.</clr-control-helper
                        >
                      </clr-select-container>
                      <clr-control-container class="clr-col-12">
                        <label class="clr-required-mark" for="price"
                          >Price</label
                        >
                        <input
                          id="{{
                            'element-' +
                              i +
                              '-price-component-' +
                              j +
                              '-price-input'
                          }}"
                          clrControl
                          type="number"
                          formControlName="price"
                        />
                        <clr-control-helper
                          class="clr-subtext"
                          style="margin-left: 2.4rem"
                          >Price to charge dependent on the value of type
                          excluding VAT.</clr-control-helper
                        >
                      </clr-control-container>
                      <clr-control-container class="clr-col-12">
                        <label class="clr-required-mark" for="vat"
                          >VAT <br /><span class="tooltip">%</span>
                        </label>
                        <input
                          id="{{
                            'element-' +
                              i +
                              '-price-component-' +
                              j +
                              '-vat-input'
                          }}"
                          clrControl
                          type="number"
                          formControlName="vat"
                        />
                        <clr-control-helper
                          class="clr-subtext"
                          style="margin-left: 2.4rem"
                          >Percentage of price to add as
                          VAT.</clr-control-helper
                        >
                      </clr-control-container>
                      <clr-control-container class="clr-col-12">
                        <label class="clr-required-mark" for="step_size"
                          >Step Size <br /><span class="tooltip">Seconds</span>
                        </label>
                        <input
                          id="{{
                            'element-' +
                              i +
                              '-price-component-' +
                              j +
                              '-step-size-input'
                          }}"
                          clrControl
                          type="number"
                          formControlName="step_size"
                        />
                        <clr-control-helper
                          class="clr-subtext"
                          style="margin-left: 2.4rem"
                          >Number of seconds per 'block' of time, this
                          associates with the 'type'. For example, if 'type' was
                          Reserved Stay Time and Price was £3.00, a step size of
                          1800 would mean billing £1.50 in 30 minute blocks.
                          <br />
                          Does not apply to *_FLAT rates.</clr-control-helper
                        >
                      </clr-control-container>
                    </clr-accordion-content>
                  </clr-accordion-panel>
                </clr-accordion>
                <button
                  id="{{ 'element-' + i + '-add-price-component-button' }}"
                  class="btn"
                  (click)="addPriceComponent(i)"
                >
                  Add Price Component
                </button>
              </div>
            </clr-accordion-content>
          </clr-accordion-panel>
          <br />
          <button
            id="add-element-button"
            class="btn"
            (click)="addTariffElement()"
          >
            Add Element
          </button>
        </clr-accordion>
      </div>
    </form>
  </div>

  <br />
  <button
    id="submit-button"
    class="btn btn-primary"
    type="submit"
    (click)="onSubmit()"
  >
    Submit
  </button>
  <!-- <button class="btn" type="button" (click)="resetForm()">Reset</button> -->
</form>
