<!-- Alert bar -->
<div class="alert alert-danger" role="alert" *ngIf="displayError">
  <div class="alert-items">
    <div class="alert-item static">
      <div class="alert-icon-wrapper">
        <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
      </div>
      <span
        class="alert-text"
        *ngIf="
          errorMessage;
          then displayErrorMessage;
          else displayGenericMessage
        "
      >
      </span>
    </div>
  </div>
</div>

<ng-template #displayErrorMessage>
  {{ errorMessage }}
</ng-template>
<ng-template #displayGenericMessage>
  Something went wrong. Please try again later.
</ng-template>

<!-- Form -->
<form clrForm clrLayout="horizontal" [formGroup]="form">
  <clr-control-container>
    <label class="clr-required-mark" for="id">Configuration ID</label>
    <input clrControl type="text" formControlName="id" />
    <clr-control-error *clrIfError="'required'">
      This field is required
    </clr-control-error>
  </clr-control-container>

  <clr-control-container>
    <label for="buffer_period_minutes">
      Buffer period
      <a
        role="tooltip"
        aria-haspopup="true"
        class="tooltip tooltip-lg tooltip-upper-right"
      >
        <cds-icon shape="info-circle"></cds-icon>
        <span class="tooltip-content">{{
          config_tooltips.buffer_period_minutes
        }}</span>
      </a>
      <br /><span class="tooltip">(minutes)</span>
    </label>
    <input clrControl type="number" formControlName="buffer_period_minutes" />
  </clr-control-container>

  <clr-control-container>
    <label class="clr-required-mark" for="reservable_period_minutes">
      Reservable period
      <a
        role="tooltip"
        aria-haspopup="true"
        class="tooltip tooltip-lg tooltip-upper-right"
      >
        <cds-icon shape="info-circle"></cds-icon>
        <span class="tooltip-content">{{
          config_tooltips.reservable_period_minutes
        }}</span>
      </a>
      <br /><span class="tooltip">(minutes)</span>
    </label>
    <input
      clrControl
      type="number"
      formControlName="reservable_period_minutes"
    />
    <clr-control-error *clrIfError="'required'">
      This field is required
    </clr-control-error>
  </clr-control-container>

  <clr-control-container>
    <label class="clr-required-mark" for="free_amendment_period_minutes">
      Free amendment period
      <a
        role="tooltip"
        aria-haspopup="true"
        class="tooltip tooltip-lg tooltip-upper-right"
      >
        <cds-icon shape="info-circle"></cds-icon>
        <span class="tooltip-content">{{
          config_tooltips.free_amendment_period_minutes
        }}</span>
      </a>
      <br /><span class="tooltip">(minutes)</span>
    </label>
    <input
      clrControl
      type="number"
      formControlName="free_amendment_period_minutes"
    />
    <clr-control-error *clrIfError="'required'">
      This field is required
    </clr-control-error>
  </clr-control-container>

  <clr-control-container>
    <label class="clr-required-mark" for="spacing_period_minutes">
      Spacing period
      <a
        role="tooltip"
        aria-haspopup="true"
        class="tooltip tooltip-lg tooltip-upper-right"
      >
        <cds-icon shape="info-circle"></cds-icon>
        <span class="tooltip-content">{{
          config_tooltips.spacing_period_minutes
        }}</span>
      </a>
      <br /><span class="tooltip">(minutes)</span>
    </label>
    <input clrControl type="number" formControlName="spacing_period_minutes" />
    <clr-control-error *clrIfError="'required'">
      This field is required
    </clr-control-error>
  </clr-control-container>

  <clr-control-container>
    <label class="clr-required-mark" for="no_show_expiry_minutes">
      No show reservation expiry period
      <a
        role="tooltip"
        aria-haspopup="true"
        class="tooltip tooltip-lg tooltip-upper-right"
      >
        <cds-icon shape="info-circle"></cds-icon>
        <span class="tooltip-content">{{
          config_tooltips.no_show_expiry_minutes
        }}</span>
      </a>
      <br /><span class="tooltip">(minutes)</span>
    </label>
    <input clrControl type="number" formControlName="no_show_expiry_minutes" />
    <clr-control-error *clrIfError="'required'">
      This field is required
    </clr-control-error>
  </clr-control-container>

  <clr-control-container>
    <label for="min_stay_minutes">
      Minimum stay
      <a
        role="tooltip"
        aria-haspopup="true"
        class="tooltip tooltip-lg tooltip-upper-right"
      >
        <cds-icon shape="info-circle"></cds-icon>
        <span class="tooltip-content">{{
          config_tooltips.min_stay_minutes
        }}</span>
      </a>
      <br /><span class="tooltip">(minutes)</span>
    </label>
    <input clrControl type="number" formControlName="min_stay_minutes" />
  </clr-control-container>

  <clr-control-container>
    <label for="max_stay_minutes">
      Maximum stay
      <a
        role="tooltip"
        aria-haspopup="true"
        class="tooltip tooltip-lg tooltip-upper-right"
      >
        <cds-icon shape="info-circle"></cds-icon>
        <span class="tooltip-content">{{
          config_tooltips.max_stay_minutes
        }}</span>
      </a>
      <br /><span class="tooltip">(minutes)</span>
    </label>
    <input clrControl type="number" formControlName="max_stay_minutes" />
  </clr-control-container>

  <clr-control-container>
    <label for="time_granularity_minutes">
      Time granularity
      <a
        role="tooltip"
        aria-haspopup="true"
        class="tooltip tooltip-lg tooltip-upper-right"
      >
        <cds-icon shape="info-circle"></cds-icon>
        <span class="tooltip-content">{{
          config_tooltips.time_granularity_minutes
        }}</span>
      </a>
      <br /><span class="tooltip">(minutes)</span>
    </label>
    <input
      clrControl
      type="number"
      formControlName="time_granularity_minutes"
    />
  </clr-control-container>

  <br />
  <!-- TODO just dont show the button if incorrect formState -->
  <!-- TODO Add back button / navigation element -->
  <button
    [disabled]="!form.valid || formState === 'VIEW'"
    class="btn btn-primary"
    type="submit"
    (click)="onSubmit()"
  >
    Save
  </button>
  <button
    id="resetButton"
    class="btn"
    type="button"
    (click)="resetForm()"
    [disabled]="formState === 'VIEW'"
  >
    Reset
  </button>
</form>
