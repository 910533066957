<a routerLink="/tariffs/create" routerLinkActive="active" class="btn btn-sm">
  Add Tariff
</a>
<a routerLink="/tariffs/assign" routerLinkActive="active" class="btn btn-sm">
  Assign Tariff
</a>

<clr-datagrid>
  <clr-dg-column [clrDgField]="'id'">Tariff ID</clr-dg-column>
  <clr-dg-column [clrDgField]="'tariff_alt_text'">Alternate Text</clr-dg-column>
  <clr-dg-column [clrDgField]="'getDaysTariffApplies(tariff)'"
    >Valid Days</clr-dg-column
  >
  <clr-dg-row *clrDgItems="let tariff of tariffs" [clrDgItem]="tariff">
    <clr-dg-cell>{{ tariff.id }}</clr-dg-cell>
    <clr-dg-cell>{{
      tariff.tariff_alt_text !== null && tariff.tariff_alt_text.length > 0
        ? tariff.tariff_alt_text[0].text
        : "None"
    }}</clr-dg-cell>
    <clr-dg-cell>{{ getDaysTariffApplies(tariff) | titlecase }}</clr-dg-cell>

    <clr-dg-row-detail *clrIfExpanded>
      <clr-accordion [clrAccordionMultiPanel]="true" class="clr-col-12">
        <clr-accordion-panel
          *ngFor="let element of tariff.elements; index as i"
        >
          <clr-accordion-title
            >Tariff element {{ i + 1 }} | Valid days:
            {{
              GetValidDaysForElement(element) | titlecase
            }}</clr-accordion-title
          >
          <clr-accordion-content *clrIfExpanded class="clr-row">
            <!-- {{ tariff | json }} -->
            <div class="clr-col-4" *ngIf="!element.restrictions">
              <p>No restrictions</p>
            </div>
            <div class="clr-col-4" *ngIf="element.restrictions">
              <table class="clr-col-12 table">
                <tbody>
                  <tr>
                    <td>Start Time</td>
                    <td>{{ element.restrictions.start_time }}</td>
                  </tr>
                  <tr>
                    <td>End Time</td>
                    <td>{{ element.restrictions.end_time }}</td>
                  </tr>
                  <tr>
                    <td>Start Date</td>
                    <td>{{ element.restrictions.start_date }}</td>
                  </tr>
                  <tr>
                    <td>End Date</td>
                    <td>{{ element.restrictions.end_date }}</td>
                  </tr>
                  <tr>
                    <td>Valid Days</td>
                    <td>{{ GetValidDaysForElement(element) | titlecase }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="clr-col-8">
              <table class="clr-col-12 table">
                <thead>
                  <tr>
                    <th class="clr-col-2">Name</th>
                    <th class="clr-col-2">Type</th>
                    <th class="clr-col-2">Price (£)</th>
                    <th class="clr-col-2">VAT (%)</th>
                    <th class="clr-col-2">Step Size (seconds)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let priceComponent of element.price_components;
                      index as j
                    "
                  >
                    <td>Pricing {{ j + 1 }}</td>
                    <td>{{ priceComponent.type }}</td>
                    <td>{{ priceComponent.price }}</td>
                    <td>{{ priceComponent.vat }}</td>
                    <td>{{ priceComponent.step_size }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </clr-accordion-content>
        </clr-accordion-panel>
      </clr-accordion>
    </clr-dg-row-detail>
  </clr-dg-row>
</clr-datagrid>
